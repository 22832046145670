<template>
  <div>
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-11">
              <h1>
                <b-icon icon="card-checklist" /> Cursos de {{ desserts.name }}
              </h1>
            </div>
            <div class="col-1">
              <h1>
                <b-icon
                  type="button"
                  @click="toPrograms"
                  icon="arrow-return-left"
                />
              </h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="container col-12">
            <b-tabs class="card" card fill>
              <b-tab
                v-for="(item, index) in desserts.list"
                :key="index"
                :title="item.name"
              >
                <b-table
                  striped
                  responsive
                  stacked="lg"
                  :items="item.list"
                  :fields="fields"
                >
                  <template #cell(name)="row">
                    {{ row.item.name + " (" }}<b>{{ row.item.capacity }}</b
                    >{{ ")" }}
                  </template>
                  <template #cell(module)="row">
                    {{ "Módulo " + row.item.numberModulus }}
                  </template>
                  <template #cell(horary)="row">
                    <b-icon-clock
                      type="button"
                      size="sm"
                      class="mx-1"
                      @click="toHorary(row.item)"
                    ></b-icon-clock>
                  </template>
                  <template #cell(people)="row">
                    <b-icon-people
                      type="button"
                      size="sm"
                      class="mx-1"
                      @click="toPeople(row.item)"
                    ></b-icon-people>
                  </template>
                  <template #cell(criteria)="row">
                    <b-icon-check-square
                      type="button"
                      size="sm"
                      class="mx-1"
                      @click="toCriteria(row.item)"
                    ></b-icon-check-square>
                  </template>
                  <template #cell(state)="row">
                    <b-badge pill :variant="returnColor(row.item.state)">{{
                      returnData(row.item.state)
                    }}</b-badge>
                  </template>
                  <template #cell(actions)="row">
                    <!--<b-icon-pencil-square
                    type="button"
                    size="sm"
                    class="mx-1"
                    @click="dateItem(row.item)"
                  ></b-icon-pencil-square>-->

                    <b-icon-trash
                      type="button"
                      size="sm"
                      class="mx-1"
                      @click="modalDelete(row.item)"
                    ></b-icon-trash>
                  </template>
                  <template #cell(url)="row">
                    <div v-if="row.item.urlGrades != null">
                      <b-link
                        target="_blank"
                        @click="
                          downloadDocument(
                            item.name,
                            row.item.name,
                            row.item.urlGrades
                          )
                        "
                        ><b-icon-cloud-download
                      /></b-link>
                    </div>
                    <div v-else>
                      <em>Vacío</em>
                    </div>
                  </template>
                </b-table>
                <!--<b-pagination
                  style="display: flex; justify-content: center"
                  v-model="currentPage"
                  pills
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                ></b-pagination>-->
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!--modal eliminar-->
    <div>
      <b-modal centered v-model="showDelete" title="Mensaje">
        <b-container fluid>
          <!--Contenido modal-->
          <h5 class="text-center">
            ¿Seguro que desea eliminar a <b>{{ data.description }}</b
            >?
          </h5>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="deleteItem(dataDelete)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Aceptar
            </button>
            <button
              type="button"
              @click="exitDelete()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    idPro: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    selected: "",
    perPage: 10,
    currentPage: 1,
    name: "",
    showDelete: false,
    data: {
      id: -1,
      index: "",
      description: "",
      dateOpening: "",
      dateClosing: "",
      state: "",
    },
    fields: [
      { key: "name", label: "Nombre (Capacidad)" },
      { key: "module", label: "Agrupación" },
      { key: "horary", label: "Horario" },
      { key: "people", label: "Personas" },
      { key: "url", label: "Notas" },
      //{ key: "criteria", label: "Criterios" },
      //{ key: "state", label: "Estado" },
      //{ key: "actions", label: "Acciones" },
    ],
    desserts: {},
  }),

  created() {
    this.initialize();
  },
  computed: {
    rows(data) {
      return data.length;
    },
    items(data) {
      this.currentPage = 1;
      return this.keyword
        ? data.filter((item) =>
            item.identifier.toLowerCase().includes(this.keyword.toLowerCase())
          )
        : data;
    },
  },

  methods: {
    downloadDocument(nameTurn, nameDocument, urlDocument) {
      this.selected = "circular";
      const resumeRef = firebase
        .storage()
        .ref("EvaluationDocuments/" + urlDocument);
      resumeRef
        .getDownloadURL()
        .then((url) => {
          // `url` is the download URL
          console.log(url);
          // This can be downloaded directly:
          const xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = function () {
            const blob = xhr.response;
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download =
              "Documento_Notas - " + nameTurn + " - " + nameDocument;
            link.click();
            URL.revokeObjectURL(link.href);
          };
          xhr.open("GET", url);
          xhr.send();
          this.selected = "";
        })
        .catch((error) => {
          this.selected = "";
          // Handle any errors
          switch (error.code) {
            case "storage/object-not-found":
              // File doesn't exist
              break;

            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect the server response
              break;
            default:
              break;
          }
        });
    },
    toHorary(data) {
      this.$router.push("/course/" + data.id + "/horary");
    },
    toPeople(data) {
      this.$router.push("/course/" + data.id + "/enrolled");
    },
    toCriteria(data) {
      this.$router.push("/course/" + data.id + "/criteria");
    },
    modalDelete(data) {
      this.showDelete = true;
      this.dataDelete = data;
      this.data.description = data.description;
    },
    exitDelete() {
      this.showDelete = false;
      this.data.description = "";
    },
    toPrograms() {
      this.$router.go(-1);
    },
    returnData(data) {
      if (data === "A") return "Activo";
      else if (data === "I") return "Inactivo";
    },
    returnColor(data) {
      if (data === "A") return "success";
      else if (data === "I") return "danger";
    },
    async initialize() {
      this.selected = "circular";
      await this.$axios
        .get("/academic/" + this.id + "/" + this.idPro + "/courses", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
          this.selected = "";
        })
        .catch((error) => {
          this.selected = "";
          this.$errorQuery(error);
        });
    },
    async deleteItem(item) {
      this.showDelete = false;
      if (item != "") {
        try {
          await this.$axios
            .get(
              "/academic/" + this.id + "/program-delete?programId=" + item.id,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.token}`,
                },
              }
            )
            .then((res) => {
              this.$message.success(res.data.content);
              this.initialize();
            });
        } catch (e) {
          this.$message.error("Error!");
          console.log(e);
        }
      }
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
</style>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
.nav-tabs .nav-link.active {
  background-color: rgb(243, 243, 243);
  border-color: rgb(243, 243, 243);
  color: black;
  font-weight: bolder;
}
.nav-tabs .nav-link {
  color: white;
}
</style>